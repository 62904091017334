import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios'
import YAML from 'yaml';
import './App.css';
import LyricsBody from './LyricsBody';
import {useLocation} from 'react-router-dom';


const LyricsPage: React.FC = () => {
  const [title, setTitle] = React.useState<string>();
  const [authors, setAuthors] = React.useState<string[]>([]);
  const [lyrics, setLyrics] = React.useState<string>('');

  const currentLocation = useLocation();
  
  useEffect(() => {
    console.log(currentLocation);
    const lyricKey = currentLocation.pathname.substring(1, currentLocation.pathname.length).replace("/", '.');
    const filePath = `/lyrics/${lyricKey}.yaml`;
    axios.get(filePath).then((response) => {
      const data = YAML.parse(response.data);
      setTitle(data.title);
      setAuthors(data.authors);
      setLyrics(data.lyrics);

    })
  }, [currentLocation])

  return (
    <div className="LyricsPage">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <header className="App-header">
        <h1>{title}</h1>
        <h4>{authors.join(", ")}</h4>
      </header>
      <hr/>
      <LyricsBody lyrics={lyrics}/>
    </div>
  );
}

export default LyricsPage;
