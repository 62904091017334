import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import axios from "axios";
import { isArray } from "util";

interface Song {
  title: string;
  authors: string[];
  route: string;
}

const ListPage: React.FC = () => {
  const [songs, setSongs] = useState<Song[]>([]);

  useEffect(() => {
    axios.get("/lyrics/index.json").then((response) => {
      setSongs(response.data);
    });
  }, []);

  return (
    <div className="ListPage">
      <Helmet>
        <title>All Songs</title>
      </Helmet>
      <header className="App-header">
        <h1>All Songs</h1>
      </header>
      <hr />
      <div className="SongList">
        <ul className="SongListItem">
          {songs.map((song) => (
            <li key={song.route}>
              <div className="SongTitle">
                <a href={song.route}>{song.title}</a>
              </div>
              <div className="SongAuthors">
                <a href={song.route}>
                  {Array.isArray(song.authors) ? song.authors.join(", ") : ""}
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ListPage;
