import React from 'react';
import './App.css';

interface Lyrics {
  lyrics: string
}

const LyricsBody: React.FC<Lyrics> = (props) => {
  const lyricSections = props.lyrics.split("\n\n");

  const renderLyricSection = (section: string) => {
    return section.split("\n").map((part, idx) => {
      return <span key={idx}>{part}<br/></span>
    })
  }

  return (
    <div className="LyricsBody">
      {lyricSections.map((section, idx) => 
        <p key={idx}>{renderLyricSection(section)}</p>
      )}
    </div>
  );
}

export default LyricsBody;
