import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import LyricsPage from "./LyricsPage";
import HomePage from "./Home";
import ListPage from "./List";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/list" element={<ListPage />} />
        <Route path="*" element={<LyricsPage />} />
      </Routes>
    </div>
  );
}

export default App;
