import React from "react";
import { Helmet } from "react-helmet";
import "./App.css";

const HomePage: React.FC = () => {
  return (
    <div className="LyricsPage">
      <Helmet>
        <title>Hi</title>
      </Helmet>
      <header className="App-header">
        <h1>Welcome to Sing This One!</h1>
      </header>
    </div>
  );
};

export default HomePage;
